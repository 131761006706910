import React from "react";
import {ChevronRight} from "react-feather";
import {IEvent, IGame} from "@webng-types/write-model";
import {ErrorBoundary} from "@webng/react-app-common";
import {TeaserWidgetList} from "./TeaserWidgetList";
import {TeaserWidgetListItem} from "./TeaserWidgetListItem";
import {TeaserWidgetSlider} from "./TeaserWidgetSlider";
import {TeaserWidgetSliderItem} from "./TeaserWidgetSliderItem";
import {TeaserLayoutLiveIndicatorStyleType, TeaserLayoutType} from "../types";
import {OnSummaryItemClickHandler} from "../Liveblog";
import {useTranslation} from "react-i18next";


function ErrorComponent() {
  return <div>Error</div>
}

// Interface for SummarySwitcher props
interface SummarySwitcherProps {
  events: IEvent[];
  game: IGame
  layout: TeaserLayoutType;
  liveblogUrl: string;
  onClick: OnSummaryItemClickHandler;
}

// SummarySwitcher component moved from LiveblogTeaserWidgetCore.tsx
export function SummarySwitcher({events, game, layout, liveblogUrl, onClick}: SummarySwitcherProps) {
  const {t} = useTranslation("liveblog");

  if (events.length <= 0) {
    return <div className="tik4-tw__empty">
      <div className="tik4-tw__empty__c">{t("liveblog.teaser_widget.empty.text")}</div>
    </div>
  }

  if(layout === 'slider') {
    return <>
        <TeaserWidgetSlider highlightCount={events.length}>
          {events.map(e =>
            <ErrorBoundary key={"highlight-slider" + e.local_id} FallbackComponent={ErrorComponent}>
              <TeaserWidgetSliderItem event={e} game={game} liveblogUrl={liveblogUrl} onClick={onClick}/>
            </ErrorBoundary>
          )}
        </TeaserWidgetSlider>
      </>
  } else {
    return <>
      <TeaserWidgetList game={game}>
        {events.map(e => <ErrorBoundary key={"highlight" + e.local_id} FallbackComponent={ErrorComponent}>
          <TeaserWidgetListItem event={e} liveblogUrl={liveblogUrl} onClick={onClick}/>
        </ErrorBoundary>)}
      </TeaserWidgetList>
    </>
  }
}

// Interface for TeaserWidget props
interface TeaserWidgetProps {
  title?: string;
  liveblogUrl: string;
  onGotoLiveblogClick: (e: React.MouseEvent) => void;
  layoutLiveIndicatorStyle: TeaserLayoutLiveIndicatorStyleType;
  layoutLiveblogLink: string;
  layout: TeaserLayoutType;
  showTitle: boolean
  limit: number;
  game: IGame
  events: IEvent[]
  onItemClick: OnSummaryItemClickHandler;
}

// Main TeaserWidget component
export function TeaserWidget({
  title,
  liveblogUrl,
  onGotoLiveblogClick,
  layoutLiveIndicatorStyle,
  layoutLiveblogLink,
  showTitle,
  layout,
  limit,
  game,
  events,
  onItemClick,
}: TeaserWidgetProps) {
  const {t} = useTranslation('liveblog');

  const titleToShow = game.meta_info?._type === "Tik::Model::GameMetaInfo::TitleGameMetaInfo" ?
    (game.meta_info.teaser_widget_title || game.meta_info.title || title) :
    title;

  return (
    <>
      {(showTitle && !!titleToShow) &&
        <div className="tik4-twt">
          {liveblogUrl
            ?
              <a className="tik4-twt__tc tik4-twt__tc--l" href={liveblogUrl} onClick={onGotoLiveblogClick}>
                <span className="tik4-tw__tc__t">{titleToShow}</span>
              </a>
            :
              <div className="tik4-twt__tc">
                <span className="tik4-tw__tc__t">{titleToShow}</span>
              </div>
          }
        </div>
      }

      {layoutLiveIndicatorStyle !== "off" &&
        <div className="tik4-twli">
          <div className="tik4-twli__i"></div>
          <div className="tik4-twli__l">
            <span className="tik4-twli__l__t">{t("liveblog.teaser_widget.live.text")}</span>
          </div>
        </div>
      }

      <SummarySwitcher
        game={game}
        events={events}
        layout={layout}
        liveblogUrl={liveblogUrl}
        onClick={onItemClick}
      />

      {(layoutLiveblogLink !== "off") &&
        <div className="tik4-twlink">
          <a className="tik4-twlink__l" href={liveblogUrl} onClick={onGotoLiveblogClick}>
            <ChevronRight className="tik4-twlink__l__i tik4-icon"/>
            <span className="tik4-twlink__l__t"><span className="tik4-twlink__l__tc">{t("liveblog.teaser_widget.link.text")}</span></span>
          </a>
        </div>
      }
    </>
  );
}

