import CancelablePromise, {CancelablePromiseType} from 'cancelable-promise';

export interface ApiClientOptions {
  interpretResponse?: boolean
  locale?: string
  clientId?: string
  clientSecret?: string
  host?: string
  apiGwHost?: string
  invalidateCallback?: () => void
  userAgent?: string
  gatekeeperDebug?: boolean
  fetchFn: typeof fetch
  fetchTimeout?: number
  log: (...args: any[]) => void
}

const defaultOptions: ApiClientOptions = {
  host: "",
  interpretResponse: true,
  locale: "en",
  fetchFn: fetch,
  log: args => {}
};

export class FatalApiError extends Error {
}

export class ApiResponseError<T = any> extends Error {
  readonly json: T
  readonly status: number

  constructor(status: number, json: T) {
    super("ApiResponseError Status " + status)
    this.json = json;
    this.status = status;
  }
}
export function isApiResponseError(error: Error | ApiResponseError | unknown): error is ApiResponseError {
  return (error as ApiResponseError).status !== undefined
}

function cancelableFetch(fetchFn: typeof fetch, timeout: number|undefined, input: string, init?: RequestInit): CancelablePromiseType<Response> {
  return new CancelablePromise<Response>((resolve, reject, onCancel) => {
    const controller = new AbortController()
    const timeoutId = timeout ? setTimeout(() => controller.abort(), timeout) : undefined;

    onCancel(() => controller.abort());

    init = init ? ({ ...init, signal: controller.signal }) : { signal: controller.signal }
    fetchFn(input, init).then(resolve).catch(reject).finally(() => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    })
  })
}

export class ApiClient {
  static API_V6_SYNC_REPORTER_META_INFOS = "/api/v6/sync/reporter_meta_infos.json";
  static API_V6_SYNC_LOAD_TICKER = "/api/v6/sync/load_ticker.json";
  static API_V6_SYNC_LOAD_EDITOR = "/api/v6/sync/load_editor.json";
  static API_V6_SYNC_POST_MODIFICATIONS = "/api/v6/sync/post_modifications.json";

  static API_V6_TICKER_SHOW = "/api/v6/ticker/show.json";

  static API_SUBSCRIPTIONS_V1_COMPILED_CAPABILITIES = "/api/subscriptions/v1/compiled_capabilities.json";
  static API_SUBSCRIPTIONS_V1_CUSTOMER_STATE = "/api/subscriptions/v1/customer_state.json";
  static API_SUBSCRIPTIONS_V1_ON_DEMAND_STATE = "/api/subscriptions/v1/on_demand_state.json";
  static API_SUBSCRIPTIONS_V1_CREDITS_SPEND_LIVEBLOGE = "/api/subscriptions/v1/credits/spend_liveblog.json";

  static API_WEB_EMBED_REQUEST = "/api/mediaservice/v3/web_embed_request.json";
  static API_WEB_EMBED = "/api/mediaservice/v3/web_embed.json";

  static API_V6_EDITOR_THIRD_PARTY_CONTENT = "/api/v6/editor_third_party_content/index.json";

  static API_COMMENTS_V1_COMMENTS_LIST = "/api/comments/v1/comments/list.json";
  static API_COMMENTS_V1_PUBLIC_CHANNEL_SETTINGS = "/api/comments/v1/owner/:ownerId/public/:channelId/version/:version/settings.json";
  static API_COMMENTS_V1_PUBLIC_CHANNEL_COMMENTS = "/api/comments/v1/owner/:ownerId/public/:channelId/comments.json";
  static API_COMMENTS_V1_COMMENTS_CREATE = "/api/comments/v1/owner/:ownerId/comments/create.json";
  static API_COMMENTS_V1_COMMENTS_UNAPPROVED_BY_REF = "/api/comments/v1/owner/:ownerId/manage/channels/list-unapproved-by-ref.json";

  static API_COMMENTS_V1_COMMENTS_UNAPPROVED = "/api/comments/v1/owner/:ownerId/manage/comments/:commentId/list-unapproved.json";
  static API_COMMENTS_V1_MANAGE_COMMENTS_APPROVE = "/api/comments/v1/owner/:ownerId/manage/comments/:commentId/approve.json";
  static API_COMMENTS_V1_MANAGE_COMMENTS_DELETE = "/api/comments/v1/owner/:ownerId/manage/comments/:commentId/delete.json";
  static API_COMMENTS_V1_MANAGE_COMMENTS_UNDO_DELETE = "/api/comments/v1/owner/:ownerId/manage/comments/:commentId/undo-delete.json";
  static API_COMMENTS_V1_MANAGE_COMMENTS_REVISE = "/api/comments/v1/owner/:ownerId/manage/comments/:commentId/revise.json";
  static API_COMMENTS_V1_MANAGE_COMMENTS_UPDATE_STARRED = "/api/comments/v1/owner/:ownerId/manage/comments/:commentId/update-starred.json";
  static API_COMMENTS_V1_MANAGE_CHANNELS_LIST_BY_REF = "/api/comments/v1/owner/:ownerId/manage/channels/list-by-ref.json";
  static API_COMMENTS_V1_MANAGE_CHANNELS_LIST_DELETED_BY_REF = "/api/comments/v1/owner/:ownerId/manage/channels/list-deleted-by-ref.json"
  static API_COMMENTS_V1_MANAGE_CHANNELS_LIST_UNAPPROVED_BY_REF = "/api/comments/v1/owner/:ownerId/manage/channels/list-unapproved-by-ref.json"
  static API_COMMENTS_V1_MANAGE_CHANNELS_SHOW = "/api/comments/v1/owner/:ownerId/manage/channels/:channelId/show.json"
  static API_COMMENTS_V1_MANAGE_COMMENTS_HIGHLIGHT = "/api/comments/v1/owner/:ownerId/manage/comments/:id/update-starred.json";
  static API_COMMENTS_V1_MANAGE_COMMENTS_COUNTERS = "/api/comments/v1/owner/:ownerId/manage/comments/counters-by-ref.json";
  static API_COMMENTS_V1_MANAGE_COMMENTS_ALL = "/api/comments/v1/owner/:ownerId/manage/comments/list.json";
  static API_COMMENTS_V1_MANAGE_COMMENTS_UNAPPROVED = "/api/comments/v1/owner/:ownerId/manage/comments/list-unapproved-by-ref.json";
  static API_COMMENTS_V1_MANAGE_COMMENTS_DELETED = "/api/comments/v1/owner/:ownerId/manage/comments/list-deleted-by-ref.json";
  static API_COMMENTS_V1_MANAGE_COMMENTS_LABELED = "/api/comments/v1/owner/:ownerId/manage/comments/list-by-label.json";
  static API_COMMENTS_V1_MANAGE_COMMENTS_LABELS = "/api/comments/v1/owner/:ownerId/manage/comments/:commentId/update-labels.json";
  static API_COMMENTS_V1_MANAGE_COMMENT_DETAIL = "/api/comments/v1/owner/:ownerId/manage/comments/:commentId/show.json";
  static API_COMMENTS_V1_MANAGE_COMMENTS_BAN_AUTHOR = "/api/comments/v1/owner/:ownerId/manage/comments/:commentId/ban-author.json";
  static API_COMMENTS_V1_MANAGE_CHANNEL_COMMENTS = "/api/comments/v1/owner/:ownerId/manage/channels/:channelId/show.json";

  static API_COMMENTS_V1_MANAGE_LABELS_ALL = "/api/comments/v1/owner/:ownerId/manage/:refId/labels/list.json";
  static API_COMMENTS_V1_MANAGE_LABELS_ADD = "/api/comments/v1/owner/:ownerId/manage/:refId/labels/create.json";
  static API_COMMENTS_V1_MANAGE_LABELS_UPDATE = "/api/comments/v1/owner/:ownerId/manage/:refId/labels/:labelId/update.json";
  static API_COMMENTS_V1_MANAGE_LABELS_DELETE = "/api/comments/v1/owner/:ownerId/manage/:refId/labels/:labelId/delete.json";

  static API_COMMENTS_V1_MANAGE_BANNED_AUTHORS_LIST = "/api/comments/v1/owner/:ownerId/manage/banned-authors/list.json";
  static API_COMMENTS_V1_MANAGE_BANNED_AUTHORS_UNBAN = "/api/comments/v1/owner/:ownerId/manage/banned-authors/:banId/unban.json";

  static API_COMMENTS_V1_SETTINGS = "/api/comments/v1/owner/:ownerId/settings";

  static API_POLLS_V1_FETCH_POLL = "/apigw/polls/v1/polls/:pollId";
  static API_POLLS_V1_CREATE_POLL = "/apigw/polls/v1/polls";
  static API_POLLS_V1_CAST_VOTE = "/apigw/polls/v1/polls/:pollId/answers/:answerId/vote";

  static API_EMBED_V4_FULL_PAGE_FETCH = "/api/embed/v4/organizations/:organizationId/fullpage/fetch.json";
  static API_EMBED_V4_FULL_PAGE_CREATE = "/api/embed/v4/organizations/:organizationId/fullpage/create.json";
  static API_EMBED_V4_FULL_PAGE_DELETE = "/api/embed/v4/organizations/:organizationId/fullpage/delete.json";

  static API_EMBED_V4_THEME_LIST = "/api/embed/v4/organizations/:organizationId/themes/list.json";
  static API_EMBED_V4_THEME_CREATE = "/api/embed/v4/organizations/:organizationId/themes/create.json";
  static API_EMBED_V4_THEME_UPDATED = "/api/embed/v4/organizations/:organizationId/themes/:id/update.json";
  static API_EMBED_V4_THEME_DELETE = "/api/embed/v4/organizations/:organizationId/themes/:id/delete.json";
  static API_EMBED_V4_THEME_SHOW = "/api/embed/v4/organizations/:organizationId/themes/:id/show.json";

  static API_EMBED_V4_CUSTOM_STYLE_LIST = "/api/embed/v4/organizations/:organizationId/custom-styles/list.json";
  static API_EMBED_V4_CUSTOM_STYLE_CREATE = "/api/embed/v4/organizations/:organizationId/custom-styles/create.json";
  static API_EMBED_V4_CUSTOM_STYLE_UPDATED = "/api/embed/v4/organizations/:organizationId/custom-styles/:id/update.json";
  static API_EMBED_V4_CUSTOM_STYLE_DELETE = "/api/embed/v4/organizations/:organizationId/custom-styles/:id/delete.json";
  static API_EMBED_V4_CUSTOM_STYLE_SHOW = "/api/embed/v4/organizations/:organizationId/custom-styles/:id/show.json";

  static API_EMBED_V4_CHANNEL = "/api/embed/v4/js/channel.json"
  static API_EMBED_V4_LIVEBLOG = "/api/embed/v4/js/liveblog.json"

  static API_SHARE_REDIRECT = "/share/event/:eventId"
  static API_V5_SHARING_POST = "/api/v5/sharing/post.json"
  static API_V5_WRITE_API_CLIENTS = "/api/v5/write/api_client/index.json"

  static API_ANALYTICS_V6_STATS_LIVEBLOG_OVERVIEW = "/api/analytics/v6/stats/liveblog/:id/overview.json"
  static API_ANALYTICS_V6_STATS_LIVEBLOG_PERIODS = "/api/analytics/v6/stats/liveblog/:id/:date/periods.json"
  static API_ANALYTICS_V6_STATS_LIVEBLOG_ORIGINS = "/api/analytics/v6/stats/liveblog/:id/:date/origins.json"
  static API_ANALYTICS_V6_STATS_LIVEBLOG_LOCATIONS = "/api/analytics/v6/stats/liveblog/:id/:date/origin/:origin/locations.json"

  static API_ANALYTICS_V7_LIVEBLOG_DASHBOARD = '/api/analytics/v7/liveblog/:id/dashboard/:date.json'
  static API_ANALYTICS_V7_ORGANIZATION_DASHBOARD = '/api/analytics/v7/organization/:id/dashboard/:date.json'


  static API_GATEKEEPER_V1_SESSION_ME = "/api/gatekeeper/v1/session/me.json";

  static API_V6_GUEST_ACCESS_LIST = "/api/v6/guest-access/list-guest-access.json"
  static API_V6_GUEST_ACCESS_CREATE = "/api/v6/guest-access/create-guest-access.json"
  static API_V6_GUEST_ACCESS_UPDATE = "/api/v6/guest-access/update-guest-access.json"
  static API_V6_GUEST_ACCESS_MAKE_LOGIN_LINK = "/api/v6/guest-access/make-login-link.json"
  static API_V6_GUEST_ACCESS_RESEND_INVITE = "/api/v6/guest-access/resend-guest-access-email.json"

  static API_EDITOR_V2_COGNITO_AUTH = "/apigw/editor/v3/cognito-auth"
  static API_EDITOR_V2_MEDIA_DOWNLOAD_PROXY = "/apigw/editor/v3/media-download-proxy"
  static API_EDITOR_V2_CONTENT_DOWNLOAD_PROXY = "/apigw/editor/v3/content-download-proxy"
  static API_EDITOR_V2_SUMMARIZE_LIVEBLOG = "/apigw/editor/v3/summarize-liveblog"

  static API_SPORTS_V2_DATA_TEAM_SEARCH = "/apigw/sports/v2/:organizationId/data/teamSearch/:dataSource"
  static API_SPORTS_V2_DATA_LEAGUE_SEARCH = "/apigw/sports/v2/:organizationId/data/leagueSearch/:dataSource"
  static API_SPORTS_V2_DATA_PLAYER_SEARCH = "/apigw/sports/v2/:organizationId/data/playerSearch/:dataSource"
  static API_SPORTS_V2_DATA_PLAYER_BY_TEAM = "/apigw/sports/v2/:organizationId/data/playerByTeam/:teamId+"
  static API_SPORTS_V2_DATA_PLAYER_BY_MATCH = "/apigw/sports/v2/:organizationId/data/playerByMatch/:matchId+"
  static API_SPORTS_V2_DATA_MATCH_WITH_EVENTS = "/apigw/sports/v2/:organizationId/data/matchWithEvents/:matchId+"
  static API_SPORTS_V2_DATA_MATCH_DETAILS = "/apigw/sports/v2/:organizationId/data/matchDetails/:matchId+"
  static API_SPORTS_V2_DATA_LEAGUE_MATCHES = "/apigw/sports/v2/:organizationId/data/matchesByRoundAndSeason/:roundId+"
  static API_SPORTS_V2_DATA_LEAGUE_STRUCTURE = "/apigw/sports/v2/:organizationId/data/roundsBySeason/:seasonId+"
  static API_SPORTS_V2_DATA_LIVEBLOG_CREATE = "/apigw/sports/v2/:organizationId/liveblog/createFromExternal"

  static API_V5_WRITE_PLAYER_INDEX = "/api/v5/write/player/index.json"
  static API_V5_WRITE_TEAM_INDEX = "/api/v5/write/team/index.json"
  static API_V5_TAGS_LIST = "/api/v5/tags/list.json"
  static API_V5_WRITE_PRICING_INDEX = "/api/v5/write/pricing/index.json"
  static API_V5_WRITE_TICKER_SHOW = "/api/v5/write/ticker/show.json"
  static API_V5_WRITE_TICKER_SHOW_BULK = "/api/v5/write/ticker/show_bulk.json"
  static API_V5_WRITE_CUSTOM_TEMPLATE_INDEX = "/api/v5/write/custom_template/index.json"
  static API_V5_WRITE_ORGANIZATION_SHOW = "/api/v5/write/organization/show.json"
  static API_V5_WRITE_ORGANIZATION_UPDATE_GAME_DEFAULTS = "/api/v5/write/organization/update_game_defaults.json"

  static API_SOCIAL_MEDIA_V4_CONTENT_STREAM_LIST = `/apigw/social-media/v4/liveblog/:liveblogId/content-streams/list`;
  static API_SOCIAL_MEDIA_V4_CONTENT_STREAM_CREATE = `/apigw/social-media/v4/liveblog/:liveblogId/content-streams/create`;
  static API_SOCIAL_MEDIA_V4_CONTENT_STREAM_UPDATE = `/apigw/social-media/v4/liveblog/:liveblogId/content-streams/:streamId/update`;
  static API_SOCIAL_MEDIA_V4_CONTENT_STREAM_DELETE = `/apigw/social-media/v4/liveblog/:liveblogId/content-streams/:streamId/delete`;
  static API_SOCIAL_MEDIA_V4_CONTENT_STREAM_MODIFY_AUTO_POST = `/apigw/social-media/v4/liveblog/:liveblogId/content-streams/:streamId/modify-auto-post`;
  static API_SOCIAL_MEDIA_V4_CONTENT_STREAM_UPDATE_ORDER = `/apigw/social-media/v4/liveblog/:liveblogId/content-streams/update-order`;
  static API_SOCIAL_MEDIA_V4_CONTENT_STREAM_POLL = `/apigw/social-media/v4/liveblog/:liveblogId/content-streams/:streamId/poll`;

  static API_SEARCH_V1_ORGANIZATION_LIVEBLOG = "/apigw/search/v1/organization/:organizationId/liveblogs"

  options: ApiClientOptions


  constructor(options: Partial<ApiClientOptions>) {
    this.options = Object.assign({}, defaultOptions, options);
  }


  _buildQueryWithAccessToken(query: Record<string, any>, accessToken?: string, isApiGw?: boolean): string {
    if (accessToken) {
      query.access_token = accessToken;
    } else if (this.options.clientId) {
      query.client_id = this.options.clientId;
      if(this.options.clientSecret) {
        query.client_secret = this.options.clientSecret;
      }
    } else {
      throw new FatalApiError("Either access token or client id must be present");
    }

    if(isApiGw) {
      query.access_token = query.access_token || ""
      query.client_id = query.client_id || ""
      query.client_secret = query.client_secret || ""
    }

    return new URLSearchParams(query).toString();
  }

  _parsePathParameters(name: string, query: Record<string, any>) {
    const returnQuery = { ...query };
    const segments = name.split("/")
    const path = segments.map(segment => {
      return segment.split(".").map(subSegment => {
        const offset = subSegment.indexOf(":") + 1
        if (!offset)
          return subSegment

        let greedy = false
        let key = subSegment.slice(offset)
        if(key.endsWith("+")) {
          key = key.slice(0, key.length - 1)
          greedy = true
        }

        const value = returnQuery[key]
        if (value === undefined) {
          throw new Error("Missing PathParam " + key)
        }
        delete returnQuery[key]

        if(!greedy) {
          return encodeURIComponent(value)
        } else {
          return value.split('/').map(encodeURIComponent).join('/')
        }
      }).join(".")
    }).join("/")

    return { path, query: returnQuery }
  }

  _apiUrl(name: string, paramsOrUndefined: any, accessToken?: string) {
    const params = Object.assign({}, paramsOrUndefined);
    const { path, query } = this._parsePathParameters(name, params);
    const isApiGw = path.startsWith("/apigw")
    const hasApiGwHost = !!this.options.apiGwHost
    const queryString = this._buildQueryWithAccessToken(query, accessToken, isApiGw && hasApiGwHost);
    const host = (isApiGw && hasApiGwHost) ? this.options.apiGwHost : this.options.host
    if (queryString) {
      return host + path + "?" + queryString;
    } else {
      return host + path;
    }
  }

  _downloadUrl(path: string, accessToken?: string) {
    const queryString = this._buildQueryWithAccessToken({}, accessToken, false);
    return this.options.host + path + "?" + queryString;
  }

  parseResponse(res: Response) {
    const contentType = res.headers.get("content-type");
    if (contentType && contentType.toLowerCase().indexOf("application/json") >= 0) {
      return res.json()
    } else if (res.status === 204 || res.headers.get("content-length") === "0") {
      return Promise.resolve(undefined);
    } else {
      throw new ApiResponseError(0, { message: "Unparsable response, please try again later. Original Status = " + res.status + "" });
    }
  }

  _handleResponse(response: Response) {
    if (this.options.interpretResponse) {
      return this.interpretResponse(response);
    } else {
      return this.parseResponse(response);
    }
  }

  interpretResponse(res: Response) {
    if (res.ok) {
      return this.parseResponse(res);
    } else if (res.status === 401) {
      this.options.invalidateCallback && this.options.invalidateCallback();
      this.throwError("", 401);
    } else if (res.status < 500) {
      return this.parseResponse(res).then(json => {
        this.throwError(json, res.status);
      })
    } else {
      this.throwError(undefined, res.status);
    }
  }

  throwError(json: any, status: number) {
    throw new ApiResponseError(status, json);
  }

  defaultHeaders(): Record<string, string> {
    const headers: Record<string, string> = {
      "Accept-Language": this.options.locale || "en"

    }
    if(this.options.userAgent) {
      headers["User-Agent"] = this.options.userAgent
    }
    if(this.options.gatekeeperDebug) {
      headers["Tik-Gatekeeper-Debug"] = "true"
    }
    return headers

  }

  getJson(name: string, query: any, accessToken?: string) {
    const url = this._apiUrl(name, query, accessToken)
    this.options.log("FETCH", "GET", url)
    return cancelableFetch(this.options.fetchFn, this.options.fetchTimeout, url, {
      method: "GET",
      headers: this.defaultHeaders()
    }).catch(err => {
      throw err
    }).then((resp) => this._handleResponse(resp))
  }

  postJson(name: string, data: any, params: any, accessToken?: string) {
    const url = this._apiUrl(name, params, accessToken)
    this.options.log("FETCH", "POST", url)
    return cancelableFetch(this.options.fetchFn, this.options.fetchTimeout, url, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        ...this.defaultHeaders()
      },
      body: JSON.stringify(data)
    }).catch(err => {
      throw err
    }).then((resp) => this._handleResponse(resp))
  }

}

export class AuthenticatedApiClient {
  private readonly apiClient: ApiClient;
  readonly accessToken?: string;

  constructor(apiClient: ApiClient, accessToken?: string) {
    this.apiClient = apiClient;
    this.accessToken = accessToken;
  }

  isAuthenticated() {
    return !!this.accessToken;
  }

  getJson(name: string, query: any = {}) {
    return this.apiClient.getJson(name, query, this.accessToken);
  }

  get<T>(name: string, query: any = {}): CancelablePromiseType<T> {
    return this.apiClient.getJson(name, query, this.accessToken) as CancelablePromiseType<T>;
  }

  buildDownloadUrl(name: string) {
    return this.apiClient._downloadUrl(name, this.accessToken)
  }

  postJson(name: string, data: any, params?: any) {
    return this.apiClient.postJson(name, data, params, this.accessToken);
  }

  post<T>(name: string, data: any, params?: any): CancelablePromiseType<T> {
    return this.apiClient.postJson(name, data, params, this.accessToken) as CancelablePromiseType<T>;
  }

  buildUrl(name: string, query: any = {}) {
    return this.apiClient._apiUrl(name, query, this.accessToken)
  }
}
