import Instagram from "./Instagram";
import Twitter from "./Twitter";
import Facebook from "./Facebook";
import ExecutableProvider from "./ExecutableProvider";
import Apester from "./Apester";
import DefaultProvider from "./DefaultProvider";
import {SimpleWebEmbedView} from "./SimpleWebEmbedView";
import {VideoWebEmbedView} from "./VideoWebEmbedView";
import {OEmbed} from "./OEmbed";
import Flourish from "./Flourish";
import React from "react";
import {Agnoplay} from "./Agnoplay";
import {YouTube} from "./YouTube";
import TruthSocial from "./TruthSocial";

export interface EmbedProviderProps {
  oembed: OEmbed
  locale: string
}

export type EmbedProvider = React.FC<EmbedProviderProps>

const EmbedProviders: Record<string,EmbedProvider|undefined> = {
  Instagram,
  Twitter,
  'X': Twitter,
  Facebook,
  Infogram: ExecutableProvider,
  Datawrapper: ExecutableProvider,
  apester: Apester,
  flourish: Flourish,
  'dpa-electionslive': ExecutableProvider,
  'dpa-sportslive': ExecutableProvider,
  'dpa-liveblog': ExecutableProvider,
  'dpa-datawrapper': ExecutableProvider,
  'dpa-addons': ExecutableProvider,
  'dpa-webgrafik': ExecutableProvider,
  'dpa-faktencheck': ExecutableProvider,
  'dpa': ExecutableProvider,
  '23degrees': ExecutableProvider,
  'apa-grafik': ExecutableProvider,
  glomex: ExecutableProvider,
  CustomEmbedCode: ExecutableProvider,
  Website: SimpleWebEmbedView,
  // YouTube: VideoWebEmbedView,
  YouTube: YouTube,
  Vimeo: VideoWebEmbedView,
  TikTok: ExecutableProvider,
  Opinary: ExecutableProvider,
  "spiegel-visualization": ExecutableProvider,
  "strava": ExecutableProvider,
  'agnoplay': Agnoplay,
  'Bluesky': ExecutableProvider,
  'pinpoll': ExecutableProvider,
  "Truth Social": TruthSocial
}

export function getProvider(oembed: OEmbed): EmbedProvider {
  return EmbedProviders[oembed.provider_name!] || DefaultProvider
}
