import {useCallback, useEffect, useMemo, useReducer, useRef, useState} from 'react'
import {useApi} from "@webng/react-app-common";
import {ApiClient, isApiResponseError} from "@webng/tickaroo-api";
import {IEvent, IGameShowResponse} from "@webng-types/write-model";
import {LiveblogData, LiveblogDataReducer, liveblogDataReducer} from "./liveblogDataReducer";
import {CancelablePromiseType} from "cancelable-promise";
import {RefreshType} from "@webng-types/embedjs";
import {TrackingFunction, findLoadMoreBottomEventId, findLoadMoreTopEventId, legacyGameShowResponseConverter} from "@webng/liveblog";

const initialReducerState: LiveblogData = {
  game: {
    _type: "Tik::Model::Game",
    sportstype: "news"
  },
  events: {},
}

type LoadEventTypes = "l_ini" | "l_mor" | "l_rfs"

interface TickerShowQuery {
  id: string
  limit?: number
  skip?: number
  include_milestone_events?: true,
  include_goal_events?: true
  include_highlight_events?: true
  reverse?: boolean,
  limit_until_event_id?: string
  limit_until_event_id_offset?: number
  continue_event_id?: string
  include_continue_event?: boolean
  tags?: string
  refresh?: number
}

interface UseLiveblogDataProps {
  id: string,
  limit: number
  deepLinkLimit: number
  deepLinkDepth: number
  reverse: boolean
  trackingFunction: TrackingFunction
  refresh: RefreshType
  initialData?: IGameShowResponse
  refreshImmediately?: boolean
}

function reversGameShowResponse(r: IGameShowResponse): IGameShowResponse {
  if(r.game?.events && r.game?.summary) {
    return {
      ...r,
      game: {
        ...r.game,
        summary: {
          ...r.game?.summary,
          filtered_first_event: r.game.summary.filtered_last_event,
          filtered_last_event: r.game.summary.filtered_first_event,
        },
        events: [...r.game.events].reverse()
      }
    }
  } else {
    return r
  }
}

export function useLiveblogData({id, limit, deepLinkLimit, deepLinkDepth, trackingFunction, refresh, reverse, initialData, refreshImmediately}: UseLiveblogDataProps) {
  const apiClient = useApi()
  const [error, setError] = useState<number|undefined>(undefined)

  const [tags, setTags] = useState<string[]|undefined>(undefined)

  const [isLoadingBackToLive, setIsLoadingBackToLive] = useState<boolean>(false)
  const [isLoadingMoreTop, setIsLoadingMoreTop] = useState<boolean>(false)
  const [isLoadingMoreBottom, setIsLoadingMoreBottom] = useState<boolean>(false)
  const [isLoadingPendingUpdate, setIsLoadingPendingUpdate] = useState<boolean>(false)

  const [data, dispatch] = useReducer<LiveblogDataReducer, IGameShowResponse|undefined>(liveblogDataReducer, initialData, (r: IGameShowResponse|undefined) => {
    if(r) {
      if(reverse) { // this is a hacky way to make reverse work by processing it in the right order and reversing it again for the output
        return liveblogDataReducer(initialReducerState, {type: 'init', response: reversGameShowResponse(r)})
      } else {
        return liveblogDataReducer(initialReducerState, {type: 'init', response: r})
      }
    } else {
      return initialReducerState
    }
  })

  const makeStatefulCall = useCallback((query: TickerShowQuery) => {
    if(query.tags === undefined && tags) {
      query = {...query, tags: tags.join()}
    }
    return apiClient.getJson(ApiClient.API_V6_TICKER_SHOW, query).then(legacyGameShowResponseConverter).then((r: IGameShowResponse) => {
      if(query.reverse) { // this is a hacky way to make reverse work by processing it in the right order and reversing it again for the output
        r = reversGameShowResponse(r)
      }
      return r;
    }).catch(e => {
      if(e && e.status !== 422) {
        trackingFunction({t: 'l_err', s: 0})
      }
      throw e
    })
  }, [apiClient, trackingFunction, tags])

  const currentRefresh = useRef<CancelablePromiseType<IGameShowResponse>>();
  const makeRefreshCall = useCallback((id: string, version: number) => {
    if(currentRefresh.current) {
      currentRefresh.current.cancel()
    }
    currentRefresh.current = makeStatefulCall( {id: id, refresh: version})
    return currentRefresh.current
  }, [makeStatefulCall])

  const reinitializeData = useCallback((eventId: string|undefined) => {
    const query: TickerShowQuery = {
      id: id,
      limit: limit,
      include_milestone_events: true,
      include_goal_events: true,
      include_highlight_events: true,
      skip: -deepLinkDepth,
      reverse
    }
    if (eventId) {
      query['continue_event_id'] = eventId
      query['include_continue_event'] = true
    }
    return makeStatefulCall(query).then(r => {
      trackingFunction({t: 'l_ini'})
      dispatch({type: 'init', response: r})
    })
  }, [makeStatefulCall, trackingFunction, id, limit, deepLinkDepth, reverse])

  const loadMoreTopEventId = useMemo(() => findLoadMoreTopEventId(data.game), [data.game])
  const loadMoreBottomEventId = useMemo(() => findLoadMoreBottomEventId(data.game), [data.game])

  const navigateToEventId = useCallback((eventId: string|undefined, loadType: LoadEventTypes = 'l_mor', dispatchType: 'init'|'more' = 'more') => {
    if (eventId && deepLinkLimit > 0) {
      const query: TickerShowQuery = {
        id: id,
        limit: deepLinkLimit,
        limit_until_event_id: eventId,
        limit_until_event_id_offset: Math.floor(limit/2),
        reverse
      }

      const continueEventId = reverse ? loadMoreTopEventId : loadMoreBottomEventId
      if(continueEventId) {
        query.continue_event_id = continueEventId
      }

      return makeStatefulCall(query)
        .then(r => {
          trackingFunction({t: loadType})
          dispatch({type: dispatchType, response: r})
        })
        .catch(e => {
          if(e.status === 422) {
            return reinitializeData(eventId)
          } else {
            throw e;
          }
        })
    } else {
      return reinitializeData(eventId);
    }
  }, [makeStatefulCall, trackingFunction, reinitializeData, id, reverse, deepLinkLimit, limit, loadMoreTopEventId, loadMoreBottomEventId])

  // initial load
  useEffect(() => {
    if(initialData?.game?.ticker_id !== id) {
      const promise = reinitializeData(undefined).catch(e => {
        if(isApiResponseError(e)) {
          setError(e.status)
        } else {
          setError(0)
        }
      })
      return () => promise.cancel()
    } else if(refreshImmediately && initialData.game.version) {
      // in case of serverside rendering, run an update
      const version = initialData.game.version
      makeRefreshCall(id, version).then(r => {
        if(r!.game!.version! > version) {
          dispatch({type: 'applyUpdate', response: r})
        }
      })
      trackingFunction({t: 'l_ini'})
    } else {
      // in case initial data from higher level widget, still trigger tracking of init data, but no need to load any
      trackingFunction({t: 'l_ini'})
    }
  }, [reinitializeData, trackingFunction, id, initialData, setError, refreshImmediately, makeRefreshCall])

  const loadMoreTop = useMemo(() => {
    if (loadMoreTopEventId) {
        return function () {
          trackingFunction({t: 't_mor', e: loadMoreTopEventId})
          setIsLoadingMoreTop(true)
          return makeStatefulCall({
            id: id,
            limit: limit,
            skip: - (limit + 1),
            continue_event_id: loadMoreTopEventId,
          }).then(r => {
            trackingFunction({t: 'l_mor'})
            dispatch({type: 'more', response: r})
          })
            .finally(() => setIsLoadingMoreTop(false))
        }
    } else {
      return undefined
    }
  }, [makeStatefulCall, id, loadMoreTopEventId, limit, setIsLoadingMoreTop, trackingFunction])

  const loadMoreBottom = useMemo(() => {

    if(loadMoreBottomEventId) {
        return function () {
          trackingFunction({t: 't_mor', e: loadMoreBottomEventId})
          setIsLoadingMoreBottom(true)
          return makeStatefulCall({
            id: id,
            limit: limit,
            continue_event_id: loadMoreBottomEventId,
          }).then(r => {
            trackingFunction({t: 'l_mor'})
            dispatch({type: 'more', response: r})
          }).finally(() => setIsLoadingMoreBottom(false))
        }
    } else {
      return undefined
    }
  }, [makeStatefulCall, id, loadMoreBottomEventId, limit, setIsLoadingMoreBottom, trackingFunction])

  const isAtTop = !loadMoreTop

  const backToTop = useMemo(() => {
    if(!isAtTop) {
      return () => {
        trackingFunction({t: 't_mor'})
        setIsLoadingBackToLive(true)
        navigateToEventId(undefined).finally(() => setIsLoadingMoreTop(false))
      }
    } else {
      return undefined
    }
  }, [navigateToEventId, isAtTop, setIsLoadingBackToLive, trackingFunction])

  // auto refresh
  useEffect(() => {
    // start timer once initial game is loaded
    if(refresh !== "off" && data.game.version && isAtTop) {
      const version = data.game.version
      let promise: CancelablePromiseType<any>|undefined = undefined
      const dispatchType = refresh === 'auto' ? 'applyUpdate' : 'setPendingUpdate'

      const interval = setInterval(() => {
        promise = makeRefreshCall(id, version).then(r => {
          trackingFunction({t: 'l_rfs'})
          if(r!.game!.version! > version) {
            dispatch({type: dispatchType, response: r})
          }
        })
      }, 20000)
      return function() {
        clearInterval(interval)
        if(promise) promise.cancel()
      }
    }
  }, [trackingFunction, id, data.game.version, isAtTop, refresh, makeRefreshCall])

  const applyPendingUpdate = useCallback(() => {
    trackingFunction({t: 't_upd'})
    setIsLoadingPendingUpdate(true)
    dispatch({type: 'applyPendingUpdate'})
    setIsLoadingPendingUpdate(false)
  }, [dispatch, setIsLoadingPendingUpdate, trackingFunction])

  const rows = useMemo(() => {
    if(data.game.events) {
      const ret = data.game.events.map(e => {
        return data.events[e.local_id!] || e
      }).filter(e => e.local_status === 0)
      if(reverse) {
        ret.reverse()
        ret.reverse = function() { throw new Error('double reverse!')}
      }
      return  ret;
    } else {
      return []
    }
  }, [data, reverse])

  const milestones = useMemo(() => {
    if(data.game?.summary?.milestones?.refs) {
      const ret = data.game?.summary?.milestones?.refs.map(ref => data.events[ref.local_id!]).filter(e => !!e) as IEvent[]
      if(reverse) ret.reverse()
      return ret
    } else {
      return undefined
    }
  }, [data.game?.summary?.milestones?.refs, data.events, reverse])

  const goals = useMemo(() => {
    if(data.game?.summary?.goals?.refs) {
      const ret = data.game?.summary?.goals?.refs.map(ref => data.events[ref.local_id!]).filter(e => !!e) as IEvent[]
      if(reverse) ret.reverse()
      return ret
    } else {
      return undefined
    }
  }, [data.game?.summary?.goals?.refs, data.events, reverse])

  const highlights = useMemo(() => {
    if(data.game?.summary?.highlights?.refs) {
      const ret = data.game?.summary?.highlights?.refs.map(ref => data.events[ref.local_id!]).filter(e => !!e).filter(e => tags ? e!.tag_ids?.find(t => tags.includes(t)) : true) as IEvent[]
      if(reverse) ret.reverse()
      return ret
    } else {
      return undefined
    }
  }, [data.game?.summary?.highlights?.refs, data.events, reverse, tags])

  const setSelectedTags = useCallback((tagIds: string[]|undefined) => {
    setTags((tagIds?.length ?? 0) > 0 ? tagIds : undefined);
    const query: TickerShowQuery = {
      id: id,
      limit: limit,
      include_milestone_events: true,
      include_goal_events: true,
      include_highlight_events: true,
      skip: -deepLinkDepth,
      reverse,
      tags: tagIds?.join() || ""
    }
    return makeStatefulCall(query).then(r => dispatch({type: 'init', response: r}))
  }, [setTags, id, limit, deepLinkDepth, reverse, makeStatefulCall])

  return {
    error,
    ...data,
    rows,
    milestones,
    goals,
    highlights,
    navigateToEventId,
    selectedTags: tags,
    setSelectedTags,
    applyPendingUpdate,
    loadMoreTop: reverse ? loadMoreBottom : loadMoreTop,
    loadMoreBottom: reverse ? loadMoreTop : loadMoreBottom,
    backToTop: reverse ? undefined : backToTop,

    isLoadingBackToLive,
    isLoadingMoreTop,
    isLoadingMoreBottom,
    isLoadingPendingUpdate
  }
}
