import React, {useCallback, useEffect, useRef} from "react";
import {EmbedProviderProps} from "./EmbedProviders";

// Parts of this are adapted from https://truthsocial.com/embed.js

let iframes = 0

export function TruthSocial({oembed}: EmbedProviderProps) {
  const iframeRef = useRef<HTMLIFrameElement|null>()

  useEffect(() => {
    function truthSocialSize(e: MessageEvent) {
       if (typeof e.data !== 'object' || e.data.type !== 'setHeight') {
        return;
      }

      if(e.origin === 'https://truthsocial.com' && e.source === iframeRef.current?.contentWindow) {
        console.log(e)
        const hasVideo = iframeRef.current?.classList.contains('truthsocial-video');
        iframeRef.current.height = hasVideo ? e.data.height + 165 : e.data.height;
      }
    }

    window.addEventListener('message', truthSocialSize)

    return () => {
      window.removeEventListener('message', truthSocialSize)
    }
  })

  const ref = useCallback((element: HTMLDivElement|null) => {
    if(element) {
      const iframe = element.getElementsByTagName('iframe')[0];

      iframeRef.current = iframe;

      iframe.scrolling      = 'no';
      iframe.style.overflow = 'hidden';
      iframe.style.borderRadius = '8px';
      iframe.style.border = '1px solid rgb(237 237 239)';

      function initIframeID() {
        iframe.contentWindow?.postMessage({
          type: 'setHeight',
          id: iframes++,
        }, '*');
      }

      iframe.onload = initIframeID

      initIframeID();

    } else {
      iframeRef.current = null;
    }
  }, [])

  return <div className="tik4-we-truthsocial" ref={ref} dangerouslySetInnerHTML={{__html: oembed.html || ""}} />
}

export default TruthSocial
